import { handleSignin } from "actions"
import { handleSigninWithTwo2Fa } from "actions"
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup"
import { MAX_LOGIN_FAILURE } from "constants/config"
import { APP_LANGUAGE } from "constants/languageConstant"
import { PITBULL_AVATAR } from "constants/pitbullAvatar"
import { useUser } from "context/UserContext"
import useCommonHook from "hooks/useCommonHook"
import moment from "moment"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import AppUsers from "services/apppUsers"
import LoginService from "services/loginService"
import useReloadResource from "./actionsLoadResource"
import useActionPopup from "./actionsPopup"
import { textToLowerCase } from "helper/common"
import { LocalStorageManager } from "helper/localStorage"

const _LOCAL_FAILED_COUNTER = "LOCAL_FAILED_COUNTER"
const _LOCAL_FAILED_TIME = "LOCAL_FAILED_TIME"

export default function useLogin() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { refresh } = useUser()
  const { translate, directCustomerService } = useCommonHook()
  const { loadGameCategoryAndGameInfo } = useReloadResource()
  const [isLoading, setIsLoading] = useState(false)
  const [errorsSubmit, setErrorsSubmit] = useState('')
  const { showPopupExpiredSession } = useActionPopup()

  function handleLogin(values, form) {
    if (isLoading) return

    const isMaxCountWrongLogin = checkIsMaxCountWrongLogin()
    if (isMaxCountWrongLogin) return

    setIsLoading(true)
    LoginService.Signin(values).then((result) => {
      setIsLoading(false)
      const { isSuccess, data, error } = result

      if (isSuccess) {
        if (
          textToLowerCase(values.username) === textToLowerCase(data.username)
          || textToLowerCase(values.username) === textToLowerCase(data.email)
        ) {
          if (form) form.resetFields();
          removeCountWrongLogin()
          updateUserAfterLogin(data)
          loadGameCategoryAndGameInfo()
          handleDirect(data)
        } else {
          showPopupExpiredSession()
        }
      } else {
        checkErrorLogin(error)
      }
    })
  }

  function checkIsMaxCountWrongLogin() {
    const LOCAL_FAILED_TIME = moment(LocalStorageManager.getItem(_LOCAL_FAILED_TIME))
    if (LOCAL_FAILED_TIME.add(10, 'minutes').isBefore(moment())) {
      LocalStorageManager.removeItem(_LOCAL_FAILED_TIME)
      LocalStorageManager.removeItem(_LOCAL_FAILED_COUNTER)
    } else {
      const countLoginToday = Number(LocalStorageManager.getItem(_LOCAL_FAILED_COUNTER) || 0)
      if (countLoginToday >= (MAX_LOGIN_FAILURE - 1)) {
        LocalStorageManager.setItem(_LOCAL_FAILED_TIME, moment().format())
        BasicAlertPopup({
          content: translate('Please_try_again_after_10_minutes'),
          icon: 'warning',
          confirmButtonColor: 'primary',
          confirmButtonText: translate('confirm'),
        })
        return true
      }
    }
    return false
  }

  function checkErrorLogin(error) {
    const errors = {
      USER_BLOCKED: {
        error: "Your_login_has_been_locked_Please_contact_customer_service_for_support",
        type: "contact"
      },
      USER_LOCKED: {
        error: "Your_login_has_been_locked_Please_contact_customer_service_for_support",
        type: "contact"
      },
      MAINTAIN_SIGN_IN: {
        error: "MAINTAIN_SIGN_IN",
      },
      MAINTAIN_ALL: {
        error: "MAINTAIN_SIGN_IN",
      },
      IP_BLOCKED: {
        error: "IP_BLOCKED",
      }
    }
    if (errors?.[error]) {
      if (errors?.[error]?.type === "contact") {
        BasicAlertPopup({
          content: translate(errors?.[error]?.error),
          icon: "warning",
          confirmButtonColor: 'primary',
          confirmButtonText: translate("contact"),
          onConfirm: () => directCustomerService(),
          showCloseButton: true,
        })
      } else {
        BasicAlertPopup({
          title: translate('registerFail'),
          color: 'danger',
          icon: 'danger',
          content: translate(errors?.[error]?.error)
        })
      }
    } else {
      checkCountWrongLogin()
    }
  }

  function checkCountWrongLogin() {
    const count = Number(LocalStorageManager.getItem(_LOCAL_FAILED_COUNTER) || 0)
    LocalStorageManager.setItem(_LOCAL_FAILED_COUNTER, count + 1)
    setErrorsSubmit(true)
    return
  }

  function removeCountWrongLogin() {
    LocalStorageManager.removeItem(_LOCAL_FAILED_COUNTER)
    LocalStorageManager.removeItem(_LOCAL_FAILED_TIME)
  }

  function linkImageRandomAvatar() {
    const randomAvatar = LocalStorageManager.getItem('random-Avatar') === 'true'
    if (randomAvatar) {
      const rand = Math.floor(Math.random() * (10 - 1 + 1)) + 1
      return Object.values(PITBULL_AVATAR).find((item) => item?.id === rand)?.path || undefined
    }
    return undefined
  }

  function updateUser({ id, data, token }) {
    AppUsers.updateInfoUser(
      {
        id,
        data: data
      },
      {
        authorization: `Bearer ${token}`
      }
    ).then((result) => {
      const { isSuccess } = result
      if (isSuccess) {
        refresh()
      }
    })
  }

  function handleDirect(data) {
    if (data.twoFAEnable) {
      history.push('/two2fa')
      dispatch(handleSigninWithTwo2Fa(data))
    } else {
      history.push('/')
      dispatch(handleSignin(data))
    }
  }
  function updateUserAfterLogin(data) {
    let payload = {}
    const languageId = APP_LANGUAGE?.[LocalStorageManager.getItem('lang') || APP_LANGUAGE.vi.value]?.id
    const userAvatar = linkImageRandomAvatar()
    if (languageId !== data?.appUserLanguageId) {
      payload.appUserLanguageId = languageId
    }
    if (userAvatar) {
      payload.userAvatar = userAvatar
    }
    if (Object.keys(payload).length > 0) updateUser({
      id: data.appUserId,
      data: payload,
      token: data.token
    });
  }

  return {
    handleLogin,
    errorsSubmit,
    setErrorsSubmit
  }
}