import React, { useEffect } from 'react'
import './index.scss'
import useCommonHook from 'hooks/useCommonHook'
import useReloadResource from '../../actionsFeatures/actionsLoadResource'
import { useDispatch } from 'react-redux'
import { setLoadResource } from 'actions/loadingResourceAction'

export function Splash(props) {
  const dispatch = useDispatch()
  const { translate } = useCommonHook()
  const {
    checkValidAPIGateway,
    loadGameCategoryAndGameInfo,
    preloadStaticImage
  } = useReloadResource()

  async function handlePreloadData() {
    await checkValidAPIGateway()
    await loadGameCategoryAndGameInfo()

    preloadStaticImage()
    setTimeout(() => {
      dispatch(setLoadResource(false))
    }, 100)
  }

  useEffect(() => {
    handlePreloadData()
    return () => { }
  }, [])

  return (
    <div className="splash max_width_screen_project">
      <div className="splashContent">
        <img src="/logoWithText.png" alt="" height={40} />
        <p>{translate('LOADING_TEXT')}</p>
      </div>
    </div>
  )
}
