const handleSetListCategory = (data) => ({
  type: "GAME_CATEGORY",
  data: data
})
const handleSetListGame = (data) => ({
  type: "GAME_INFO",
  data: data
})

const handleSetGameCategoryActive = (data) => ({
  type: "GAME_CATEGORY_ACTIVE",
  data: data
})

export { handleSetListCategory, handleSetListGame, handleSetGameCategoryActive }
