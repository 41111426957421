import axios from 'axios'
import { HOST } from './../constants/url'
import { message } from 'antd'
import { getQueryString, trimStrings } from '../helper/common'
import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import { APP_LANGUAGE } from 'constants/languageConstant'
import { LocalStorageManager } from 'helper/localStorage'
import { encryptAes256CBC } from 'constants/EncryptionFunctions'
import { decryptAes256CBC } from 'constants/EncryptionFunctions'

const messageMissingAuth = {
  en: 'Login session has expired, please log in again',
  vi: 'Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại',
  cn: '登录会话已过期，请重新登录',
  in: 'Sesi login telah berakhir, silakan login kembali',
  ko: '로그인 세션이 만료되었습니다. 다시 로그인해 주세요',
  ms: 'Sesi log masuk telah tamat tempoh, sila log masuk semula',
  th: 'เซสชั่นการเข้าสู่ระบบหมดอายุแล้ว กรุณาเข้าสู่ระบบอีกครั้ง'
}
const messageWhenMaintain = {
  en: 'The system is maintenance. Please come back later',
  vi: 'Hệ thống đang bảo trì. Vui lòng quay lại sau',
  cn: '系统正在维护中。请待会再过来',
  in: 'Sistemnya adalah pemeliharaan. Silakan kembali lagi nanti',
  ko: '시스템은 유지보수입니다. 나중에 다시 오시기 바랍니다',
  ms: 'Sistem ini adalah penyelenggaraan. Sila balik nanti',
  th: 'อยู่ในระหว่างการบำรุงรักษาระบบ โปรดกลับมาใหม่'
}

function send({ method = 'get', path, data = null, query = null, headers = {}, newUrl, disableAuth }) {
  return new Promise((resolve) => {
    let url = HOST + `${path}${getQueryString(query)}`
    if (newUrl) {
      url = `${newUrl}${getQueryString(query)}`
    }
    const dataString = LocalStorageManager.getItem('data')
    if (!disableAuth && dataString && !headers.authorization) {
      const newData = JSON.parse(dataString)
      headers.authorization = newData.token ? `Bearer ${newData.token}` : ''
    }
    headers.appUserLanguageId = Object.values(APP_LANGUAGE).find((item) => item.value === (LocalStorageManager.getItem('lang') || 'en'))?.id

    let encryption = data
    if(process.env.NODE_ENV !== 'development'){
      const newStringData = JSON.stringify(trimStrings(data))
      encryption = encryptAes256CBC(newStringData) // mã hoá gửi đi
    }

    axios({
      method,
      url,
      data: encryption,
      headers
    })
      .then((result) => {
        const data = result.data
        let decryption = data
        if (data.idEn) {
          decryption = decryptAes256CBC(data) // mã hoá lấy về
        }
        return resolve(decryption)
      })
      .catch((error) => {
        const { response = {} } = error
        let result = response.data ? response.data : {}
        if (result.idEn) {
          result = decryptAes256CBC(result) // mã hoá lấy về
        }
        const statusCode = result?.statusCode
        if (statusCode === 599) {
          BasicAlertPopup({
            title: messageWhenMaintain?.[LocalStorageManager.getItem('lang')],
            color: 'danger',
            icon: 'danger',
            showCloseButton: true
          })
          return resolve(result)
        } else if (statusCode === 401 || statusCode === 505 || statusCode === 403) {
          message.config({ maxCount: 1 })
          message.warn(messageMissingAuth?.[LocalStorageManager.getItem('lang')])
          setTimeout(() => {
            LocalStorageManager.removeItem('data')
            LocalStorageManager.removeItem('token')
            LocalStorageManager.removeItem('isUserLoggedIn')
            window.location.href = '/'
          }, 1000)
        } else {
          return resolve(result)
        }
      })
  })
}

export default {
  send
}
